<template>
	<div class="container">
		<div class="title text-c">
			浙工大志愿者之家隐私政策
		</div>
		<div class="content">
			本指引是浙工大志愿者之家小程序开发者（以下简称“开发者”）为处理你的个人信息而制定。
		</div>
		<div class="title">开发者处理的信息</div>
		<div class="content">根据法律规定，开发者仅处理实现小程序功能所必要的信息。</div>
		<div class="content">•开发者收集你的地理位置，用于推荐同城的志愿服务活动。</div>
		<div class="content">•开发者收集你选中的照片或视频，用于参加志愿服务活动后分享活动体验。</div>
		<div class="content">•开发者收集你选中的文件，用于提交志愿服务活动报名的补充资料。</div>
		<div class="content">•开发者收集你的姓名、手机号码、身份证号码灯相关学生信息用于申请成为实名认证志愿者。</div>
		<div class="content">志愿服务活动组织机构处理的信息</div>
		<div class="content">小程序上发布的志愿服务活动均由活动所属的志愿服务活动组织机构发起，开发者受志愿服务活动组织机构委托提供平台技术能力支持。你理解并同意，为了报名参加志愿服务活动，你的学生等相关必要信息将提供给志愿服务活动组织机构，满足志愿服务活动组织机构数据管理、统计等要求。开发者会要求志愿服务活动组织机构根据法律法规要求来处理、保存你的信息，但开发者不对志愿服务活动组织机构的行为承担任何保证责任。</div>
		<div class="title">你的权益</div>
		<div class="content">开发者承诺，除法律法规另有规定外，开发者对你的信息的保存期限应当为实现处理目的所必要的最短时间。</div>
		<div class="content">关于位置信息，你可以通过以下路径：小程序的右上角…-点击设置-位置信息-点击不允许或仅在使用小程序期间。</div>
		<div class="content">关于你的个人信息，你可以通过以下方式与开发者联系，行使查阅、复制、更正、删除等法定权利。</div>
		<div class="content">小程序内部客服入口：我的页面点击问题咨询按钮即可联系官方客服 </div>
		<div class="title">
            开发者对信息的存储
        </div>
		<div class="content">开发者承诺，除法律法规另有规定外，开发者对你的信息的保存期限应当为实现处理目的所必要的最短时间。</div>
		<div class="title">
            信息的使用规则
        </div>
		<div class="content">开发者将会在本指引所明示的用途内使用收集的信息</div>
		<div class="content">
			如开发者使用你的信息超出本指引目的或合理范围，开发者必须在变更使用目的或范围前，再次以站内信通知方式告知并征得你的明示同意。
		</div>
		<div class="title">信息对外提供</div>
		<div class="content">除本协议约定外，开发者承诺，不会主动共享或转让你的信息至任何第三方，如存在确需共享或转让时，开发者应当直接征得或确认第三方征得你的单独同意。</div>
		<div class="content">
			除本协议约定外，开发者承诺，不会对外公开披露你的信息，如必须公开披露时，开发者应当向你告知公开披露的目的、披露信息的类型及可能涉及的信息，并征得你的单独同意。
你认为开发者未遵守上述约定，或有其他的投诉建议、或未成年人个人信息保护相关问题，可通过以下方式与开发者联系；或者向微信进行投诉。
		</div>
		<div class="content">你认为开发者未遵守上述约定，或有其他的投诉建议、或未成年人个人信息保护相关问题，可通过以下方式与开发者联系；或者向微信进行投诉。</div>
		<div class="content">
			您使用产品或服务时使用的设备的属性、链接、状态信息。例如，设备型号、操作系统版本、唯一设备ID（将用于标识您为予睿教育达人版的用户）、电信运营商、使用的语言、硬件型号、设备配置、国际移动设备身份码IMEI、网络设备硬件地址MAC、广告标识符IDFA、设备传感器数据，设备应用安装列表等。
		</div>
		<div class="content">小程序内部客服入口：我的页面点击问题咨询按钮即可联系官方客服 </div>
		
		<div class="content right"> 更新日期：2023-02-24</div>
		<div class="content right"> 生效日期：2023-02-24 </div>
	</div>
</template>

<script>
	export default {
		data() {
			return {

			}
		},
		mounted() { //实例被挂载后调用
			let that = this;
			let erd = elementResizeDetectorMaker()
			erd.listenTo(document.getElementById("divport"), function(element) {
				//监听div宽度变化
				let width = element.offsetWidth
		 	let height = element.offsetHeight
				console.log(width)
				console.log(height)
				that.$nextTick(function() {
					let fontSize = width / 80 + 'px';
					let html = document.documentElement;
					html.style.fontSize = fontSize;
					console.log('fontSize', html.style.fontSize)
				})
			})
		},
		created() {},
		methods: {

		}
	}
</script>

<style scoped="">
	html {
		/*7.5为设计稿的宽度*/
		font-size: -webkit-calc(100vw/7.5);
		/*此时定义了在750的设计稿下  1rem=100px*/
	}

	body {
		font-size: 0.24rem;
	}

	.container {
		padding:0.3rem;
	}

	.content {
		font-size: 0.6rem;
		line-height: 1.0rem;
		word-break: break-all;
	}
    .text-c{
        text-align:center
    }
	.title {
		font-size: 0.9rem;
		margin: 0.2rem 0;
		font-weight: 600;
	}
    .right{
        text-align:right
    }
</style>
